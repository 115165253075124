* {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background: transparent;
    font-weight: normal;
    font-style: normal;
    font-family: "Inter-Regular";
    text-decoration: none;
    outline: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

img {
    user-select: none;
    /* supported by Chrome and Opera */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
}

*::-moz-selection {}

*::selection {}

ol,
ul {
    list-style: none;
}

blockquote {
    quotes: none;
}

blockquote:before,
blockquote:after {
    content: "";
    content: none;
}

del {
    text-decoration: line-through;
}

html {
    background: #111315;
}

body {
    font-size: 1.0625rem;
    color: #333;
    background: #f9fbfd;
    overflow-x: hidden;
    min-width: 20rem;
    position: relative;
    font-family: "poppinsregular";
}

input,
textarea,
select {
    font-family: "poppinsregular";
    background: unset;
    border: unset;
}

textarea,
input[type="number"]::-webkit-inner-spin-button,
input[type="text"],
input[type="button"],
input[type="submit"] {
    -webkit-appearance: none;
    border-radius: 8px;
}

*:focus {
    outline: none;
}

a {
    color: #333;
}

.wrapper {
    width: 90%;
    max-width: 1350px;
    margin: 0 auto;
}

.submit:hover {
    filter: alpha(opacity=85);
    -moz-opacity: 0.85;
    -khtml-opacity: 0.85;
    opacity: 0.85;
}

.thumb {
    display: block;
    width: 100%;
}

.thumb img {
    display: block;
    width: 100%;
}

p {
    line-height: 1.6em;

    .section-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .section {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
    }

    .fade-in {
        animation: fadeIn 1s ease-in-out forwards;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    #section1 {
        background-color: #1abc9c;
    }

    #section2 {
        background-color: #3498db;
    }

    #section3 {
        background-color: #9b59b6;
    }

    .scroll-link {
        margin-top: 20px;
        cursor: pointer;
        color: #fff;
        background-color: #16a085;
        padding: 10px 20px;
        border-radius: 5px;
        text-decoration: none;
    }

    .scroll-link:hover {
        background-color: #1abc9c;
    }

    /* font-size: 1rem;
    color: #fff; */
}

br.clear {
    clear: both;
    line-height: 0;
}

.slick-arrow {
    display: none !important;
}

@font-face {
    font-family: "montserrat";
    src: url("../fonts/Montserrat-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "kenexy";
    src: url("../fonts/KonexyPersonalUse.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

/* Animation page switch */
@keyframes drop {
    0% {
        opacity: 0;
        transform: translateY(-80px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}


.active {
    color: white !important;
}

body::-webkit-scrollbar {
    display: none;
}

.gradient {
    font-family: 'kenexy';
    background-image: linear-gradient(to bottom, rgba(47, 83, 164, 1), rgba(18, 19, 62, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* For Safari */
    background-clip: text;
    color: transparent;
}

/* Map Styles  */

.leaflet-container {
    height: 500px;
    width: 100%;
    border-radius: 20px;

}

.chip {
    display: inline-block;
    padding: 0 25px;
    height: 50px;
    font-size: 16px;
    line-height: 50px;
    border-radius: 25px;
    background-color: #f1f1f1;
}

.chip img {
    float: left;
    margin: 0 10px 0 -25px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.static-map {
    width: 250px;
    height: 250px;
}

.leaflet-container .leaflet-control-attribution {
    display: none;
}

/* box icon  */
.bx .bxs-chevron {
    color: #111315;
    font-size: 40px;
}

/* Gallery Icon Styles  */
.galleryWrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;

}

.galleryWrap .single {
    width: 100%;
    height: 280px;
    cursor: pointer;
    overflow: hidden;
}

.galleryWrap .single img {
    width: 100%;
    border-radius: 12px;
    height: 100%;
    display: block;
    object-fit: cover;
    transition: all 0.3s ease;
}

.galleryWrap .single img:hover {
    transform: scale(1.02);
}

.sliderWrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.sliderWrap .btnClose,
.sliderWrap .btnPrev,
.sliderWrap .btnNext {
    position: fixed;
    cursor: pointer;
    padding: 8px;
    /* opacity: 0.6; */
    color: #fff;
    z-index: 9999;
}

.btnNext:hover,
.btnPrev:hover,
.btnClose:hover {
    opacity: 1;
}

.sliderWrap .btnClose {
    top: 100px;
    right: 90px;

}

.sliderWrap .btnPrev {
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
}

.sliderWrap .btnNext {
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
}

.fullScreenImage {
    width: calc(100% - 40px);
    /* height: calc(100% - 40px); */
    display: flex;
    align-items: center;
    justify-content: center;
}

.fullScreenImage img {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}



@media all and (max-width: 1280px) {
    .galleryWrap .single {
        height: 240px;
    }
}

@media screen and (max-width: 1180px) {
    .sliderWrap .btnClose {
        top: 133px;
        right: 50px;
    }

    .galleryWrap .single {
        height: 240px;
    }
}

@media all and (max-width: 1080px) {
    .galleryWrap .single {
        height: 200px;
    }
}

@media screen and (max-width: 768px) {
    .leaflet-container {
        height: 300px;
        width: 100%;
    }
}

@media screen and (max-width: 640px) {
    .galleryWrap {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 480px) {
    .leaflet-container {
        height: 225px;
        width: 100%;
    }

    .sliderWrap .btnClose {
        top: 214px;
        right: 25px;
    }

    .galleryWrap .single {
        height: 135px;
    }
}

@media screen and (max-width: 430px) {
    .galleryWrap .single {
        height: 125px;
    }
}